import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Alert, Button, Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import DepartureService from '~/services/cruises/DepartureService';
import OfferService from '~/services/cruises/OfferService';

import { DATA_FEED_DIFFS_DEFAULT, DataFeedDiffs } from './constants';
import { formatDataFeedDiffs } from './diffs-format';

interface Props {
  offerId: string;
  sailingId: string;
}

const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    display: 'flex',
  },
  {
    flex: 1,
    field: 'database',
    headerName: 'Currently',
    display: 'flex',
  },
  {
    flex: 1,
    field: 'dataFeed',
    headerName: 'New (Coming from FTP)',
    display: 'flex',
  },
];

export default function SailingCoreChangesDetails(props: Props) {
  const { sailingId, offerId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [fetchingDataFeedDiffs, setFetchingDataFeedDiffs] = useState<Utils.FetchingState>('idle');
  const [fetchingRebuildOffer, setFetchingRebuildOffer] = useState<Utils.FetchingState>('idle');
  const [fetchingRebuildDeparture, setFetchingRebuildDeparture] = useState<Utils.FetchingState>('idle');
  const [dataFeedDiffs, setDataFeedDiffs] = useState<DataFeedDiffs[]>(DATA_FEED_DIFFS_DEFAULT);

  const [{ isIsolatedDepartureFetched, isIsolatedDepartureCurrent }, setIsIsolatedDeparture] = useState({
    isIsolatedDepartureFetched: false,
    isIsolatedDepartureCurrent: false,
  });

  const isLoading = useMemo(() => {
    return [fetchingRebuildDeparture, fetchingRebuildOffer].includes('loading');
  }, [fetchingRebuildDeparture, fetchingRebuildOffer]);

  const fetchDataFeedDiffs = useCallback(
    async (departureId: string): Promise<void> => {
      setFetchingDataFeedDiffs('loading');

      const fetchedDataFeedDiffs = await DepartureService.getDataFeedDiffsByDepartureId(departureId);

      if (!fetchedDataFeedDiffs?.result) {
        setFetchingDataFeedDiffs('failed');
        setDataFeedDiffs(DATA_FEED_DIFFS_DEFAULT);
        enqueueSnackbar('No diffs found', { autoHideDuration: 5000, variant: 'warning' });
      } else {
        setFetchingDataFeedDiffs('success');
        setDataFeedDiffs(formatDataFeedDiffs(fetchedDataFeedDiffs.result.core));

        setIsIsolatedDeparture({
          isIsolatedDepartureFetched: fetchedDataFeedDiffs.result.isIsolatedDeparture,
          isIsolatedDepartureCurrent: fetchedDataFeedDiffs.result.isIsolatedDeparture,
        });
      }
    },
    [enqueueSnackbar],
  );

  const rebuildDeparture = useCallback(async () => {
    if (!sailingId) return;
    setFetchingRebuildDeparture('loading');

    try {
      const res = await DepartureService.rebuildDeparture({
        departureId: sailingId,
        isIsolatedDeparture: isIsolatedDepartureCurrent,
      });

      setFetchingRebuildDeparture('success');
      enqueueSnackbar(
        `Departure rebuilt successfully. Move from Offer ID: ${res.result.fromOfferId} to ${res.result.toOfferId}`,
        { autoHideDuration: 5000, variant: 'success' },
      );
    } catch {
      setFetchingRebuildDeparture('failed');
      enqueueSnackbar('Something wrong happened. Please contact the Cruises team.', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, [enqueueSnackbar, sailingId, isIsolatedDepartureCurrent]);

  const rebuildOfferOrDeparture = useCallback(async () => {
    if (!offerId) return;

    const isIsolatedDepartureChanged = isIsolatedDepartureFetched !== isIsolatedDepartureCurrent;

    if (isIsolatedDepartureChanged) {
      await rebuildDeparture();
      return;
    }

    setFetchingRebuildOffer('loading');

    await OfferService.rebuildOffer(offerId)
      .then((res) => {
        setFetchingRebuildOffer('success');
        enqueueSnackbar(
          `Offer rebuilt successfully. Move from Offer ID: ${res.result.fromOfferId} to ${res.result.toOfferId}`,
          { autoHideDuration: 10000, variant: 'success' },
        );
      })
      .catch(async () => {
        setFetchingRebuildOffer('failed');
        enqueueSnackbar(
          'It will not be possible to rebuild the entire Offer. Starting to rebuild only this Departure...',
          {
            variant: 'warning',
            autoHideDuration: 10000,
          },
        );

        await rebuildDeparture();
      });
  }, [enqueueSnackbar, offerId, rebuildDeparture, isIsolatedDepartureFetched, isIsolatedDepartureCurrent]);

  useEffect(() => {
    if (sailingId) fetchDataFeedDiffs(sailingId);
  }, [fetchDataFeedDiffs, sailingId]);

  const handleIsolatedDepartureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsIsolatedDeparture({ isIsolatedDepartureFetched, isIsolatedDepartureCurrent: event.target.checked });
  };

  return (
    <Stack direction="column" spacing={4} mt={5}>
      {fetchingDataFeedDiffs === 'loading' && <Spinner size={24} />}

      {fetchingDataFeedDiffs === 'success' && (
        <>
          <PageSubheader title="Sailing Core Changes" />

          <DataGrid
            autoHeight
            hideFooter
            columns={columns}
            disableColumnMenu
            disableColumnFilter
            rows={dataFeedDiffs}
            hideFooterPagination
            disableRowSelectionOnClick
            rowCount={dataFeedDiffs.length}
            slots={{ pagination: GridPagination }}
          />

          <Stack direction="row" justifyContent="end">
            <FormControlLabel
              control={<Checkbox checked={isIsolatedDepartureCurrent} onChange={handleIsolatedDepartureChange} />}
              label="Isolate to separate offer"
            />

            <Button
              size="large"
              variant="contained"
              onClick={rebuildOfferOrDeparture}
              disabled={isLoading}
              endIcon={isLoading ? <CircularProgress size={16} /> : undefined}
            >
              Rebuild Offer
            </Button>
          </Stack>

          <Alert severity="info">
            <Typography variant="body1">
              <strong>NOTE:</strong> Please be aware that rebuilding the Offer will generally move the Matches to
              another Offer. This process may take a few minutes to reflect changes in the Customer Portal.
            </Typography>
          </Alert>
        </>
      )}
    </Stack>
  );
}
